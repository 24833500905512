// 登录接口
import {
	axios
} from '../../utils/http.js'

const ClassApi = {
	ClassList: '/v3/Class/List',//列表
	ClassAdd: '/v3/Class/Add',//添加
	ClassEdit: '/v3/Class/Edit',//编辑
	ClassDel: '/v3/Class/Del',//删除
	DevSenList: '/v3/DeviceSensor/List',//设备列表
	ClassBindAdd: '/v3/Class/Bind_Add',//绑定
	DevList: '/v3/Device/DeviceClassBind_List',//已绑定列表
	ClassBindDel: '/v3/Class/Bind_Del',//删除已绑定
}
//设备列表
export function ClassBindAdd(data) {
	return axios({
		url: ClassApi.ClassBindAdd,
		method: 'post',
		data:data
	})
}
//设备列表
export function DevList(data) {
	return axios({
		url: ClassApi.DevList,
		method: 'post',
		data:data
	})
}
//设备列表
export function ClassBindDel(data) {
	return axios({
		url: ClassApi.ClassBindDel,
		method: 'post',
		data:data
	})
}



//设备列表
export function DevSenList(data) {
	return axios({
		url: ClassApi.DevSenList,
		method: 'post',
		data:data
	})
}
//删除
export function ClassDel(data) {
	return axios({
		url: ClassApi.ClassDel,
		method: 'post',
		data:data
	})
}
//编辑
export function ClassEdit(data) {
	return axios({
		url: ClassApi.ClassEdit,
		method: 'post',
		data:data
	})
}
//添加
export function ClassAdd(data) {
	return axios({
		url: ClassApi.ClassAdd,
		method: 'post',
		data:data
	})
}
//列表
export function ClassList(data) {
	return axios({
		url: ClassApi.ClassList,
		method: 'post',
		data:data
	})
}