<template>
	<div class="DeviceClass">
		<div class="DeviceClass-T">
			<div class="DeviceClassL">
				<Breadcrumb></Breadcrumb>
				<div class="RightsManagement-searchs">
					<div class="RightsManagement-searchs1">
						<inputs @inputs="inputValue" :inputShow="true" inputPlace="请输入名称"></inputs>
					</div>
					<div class="DeviceClassit-mai1-btn">
						<!-- <el-button type="danger" icon="el-icon-search">查询</el-button> -->
					</div>
				</div>
			</div>
			<div class="DeviceClassR">
				<div class="RightsManagement-searchsMai">
					<!-- <el-button type="danger" icon="el-icon-plus" @click="clickNew()">新增</el-button> -->
					<el-button v-permission="'DeviceClass:Add'" @click="clickNew()" type="primary" icon="el-icon-plus">新增分类</el-button>
				</div>
			</div>
		</div>
		<div class="DeviceClass-B">
			<el-table :data="tableData" style="width: 100%;margin-bottom: 20px;" border>
				<template slot="empty">
					<noData></noData>
				</template>
				<el-table-column type="index" width="50" label="序号">
				</el-table-column>
				<el-table-column prop="T_name" label="分类名称">
				</el-table-column>
				<el-table-column prop="address" label="操作" :show-overflow-tooltip="true">
					<template slot-scope="scope">
						<el-button v-permission="'DeviceClass:Bind'" size="small" @click="SetBinding(scope.row)">绑定</el-button>
						<el-button v-permission="'DeviceClass:Edit'" size="small" @click="SetEdit(scope.row)">编辑</el-button>
						<el-button v-permission="'DeviceClass:Del'" size="small" @click="SetDel(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="margin-top: 50px;">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
					:current-page="pages.page" :page-size="pages.page_z"
					layout="total, prev, pager, next, jumper" :total="total">
				</el-pagination>
			</div>
		</div>
		<el-dialog :title="titles" :visible.sync="dialogVisible" :append-to-body="true">
			<div class="NewUser">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="60px" class="demo-ruleForm">
					<el-form-item label="名称" prop="T_name">
						<el-input v-model="ruleForm.T_name"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('ruleForm')">立即提交</el-button>
						<el-button @click="dialogVisible=false">取消</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>
		<el-drawer title="绑定" :visible.sync="drawer" @close="closes" direction="rtl" :append-to-body="true">
			<div style="padding:0 20px;height: 100%;">
				<el-tabs v-model="activeName" @tab-click="handleClick" style="height: 100%;display: flex;flex-direction: column;">
					<el-tab-pane label="已绑定传感器" name="first" style="height: 100%;">
						<div class="y-table" infinite-scroll-delay="300" infinite-scroll-immediate="false" v-infinite-scroll="scroll1">
                            <div style="display: flex;align-items: center;">
                                <div class="y-table-item" style="width: 50px;">序号</div>
                                <div class="y-table-item" style="flex: 1;">设备名称</div>
                                <div class="y-table-item" style="flex: 1;">操作</div>
                            </div>
                            <div v-for="item,index in tableData1" :key="index" style="display: flex;align-items: center">
                                <div class="y-table-item" style="width: 50px;;">{{index+1}}</div>
                                <div class="y-table-item" style="flex: 1;">{{item.T_name}}</div>
                                <div class="y-table-item" style="flex: 1;">
                                    <el-button type="primary" icon="el-icon-delete" size="small" @click="delBang(item)">删除</el-button>
                                </div>
                            </div>
						</div>
					</el-tab-pane>
                    <el-tab-pane label="设备列表" name="second" style="height: 100%;overflow: hidden;box-sizing: border-box;">
                        <div style="display: flex;flex-direction: column;height: 100%;">
                            <el-input v-model="pages2.T_name" placeholder="请输入内容" style="width:80%;margin-bottom: 20px;">
                                <el-button slot="append" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
                            </el-input>
                            <div class="y-table" style="flex: 1;" infinite-scroll-delay="300" infinite-scroll-immediate="false" v-infinite-scroll="scroll2">
                                <div style="display: flex;align-items: center;">
                                    <div class="y-table-item" style="width: 50px;">序号</div>
                                    <div class="y-table-item" style="flex: 1;">设备名称</div>
                                    <div class="y-table-item" style="flex: 1;">操作</div>
                                </div>
                                <div v-for="item,index in tableData2" :key="index" style="display: flex;align-items: center">
                                    <div class="y-table-item" style="width: 50px;;">{{index+1}}</div>
                                    <div class="y-table-item" style="flex: 1;">{{item.T_name}}</div>
                                    <div class="y-table-item" style="flex: 1;">
                                        <el-button type="primary" icon="el-icon-paperclip" size="small" @click="classBang(item)">绑定</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-drawer>
	</div>
</template>

<script>
	import {
		ClassList,
		ClassAdd,
		ClassEdit,
		ClassDel,
		DevSenList,
		ClassBindAdd,
		DevList,
		ClassBindDel,
	} from "@/api/equipmentManagement/DeviceClass.js"
	export default {
		data() {
			return {
                count: 10,
                isScroll:false,
                isBindScroll:false,
				total: 50,
				pages: {
					T_name: '',
					page: 1,
					page_z: 10,
				},
				pages1:{//已绑定
					T_class_id :'',
					page: 1,
					page_z: 20,
				},
				pages2:{//设备列表
					T_name: '',
					page: 1,
					page_z: 20,
					T_state:1,
				},
				tableData: [],
				tableData1:[],
				tableData2:[],
				Page_size1:1,
				Page_size2:1,
				drawer: false,
				activeName: 'first',

				titles: '',
				dialogVisible: false,
				ruleForm: {
					T_name: '',
				},
				rules: {
					T_name: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],
				}
			}
		},
		mounted() {
			this.GetCassListApi()
		},
		methods: {
			closes(){
				this.tableData1 = []
				this.activeName = 'first'
			},
			handleClick(tab) {
				if(tab.name=='first'){
					this.pages1.page = 1
					this.Page_size1 = 1
					this.tableData1 = []
					this.scrollBind()
				}else{
					this.pages2.page = 1
					this.Page_size2 = 1
					this.tableData2 = []
					this.scrollList()
				}
			},
			delBang(e){
				ClassBindDel({
					T_class_id:this.pages1.T_class_id,
					T_sn:e.T_sn,
					T_id:e.T_id
				}).then(res=>{
					if(res.data.Code===200){
						this.$message.success('删除绑定成功')
						this.pages1.page = 1
						this.tableData1 = []
						this.scrollBind()
					}
				})
			},
			classBang(e){//点击绑定
				ClassBindAdd({
					T_class_id:this.pages1.T_class_id,
					T_sn:e.T_sn,
					T_id:e.T_id
				}).then(res=>{
					if(res.data.Code===200){
						this.$message.success('绑定成功')
					}
				})
			},
			scrollBind() {//已绑定触底加载
				DevList(this.pages1).then(res=>{
					if(res.data.Code===200 && res.data.Data.DeviceSensor_lite!=null){
						this.Page_size1 = res.data.Data.Page_size
						this.tableData1 = [...this.tableData1,...res.data.Data.DeviceSensor_lite]
                        setTimeout(() => {
                            this.isBindScroll=false
                        }, 100);
					}	
				})
			},
			scroll1(){ 
                this.pages1.page = this.pages1.page + 1
                this.scrollBind()
			},
			scroll2(){
                this.pages2.page = this.pages2.page + 1
                this.scrollList()
			},
			scrollList() {//触底加载
				DevSenList(this.pages2).then(res=>{
					if(res.data.Code===200 && res.data.Data.Data!=null){
						this.Page_size2 = res.data.Data.Page_size
						this.tableData2 = [...this.tableData2,...res.data.Data.Data]
                        setTimeout(() => {
                            this.isScroll=false
                        }, 100);
					}	
				})
			},
			search() {//搜索
				////console.log('搜索')
				this.pages2.page = 1
				this.pages2.Page_size2 = 1
				this.tableData2 = []
				this.scrollList()
			},
			SetBinding(e) { //绑定
				this.pages1.page = 1
				this.pages1.T_class_id = e.Id
				this.drawer = true
				this.scrollBind()
			},
			GetCassListApi() { //列表
				ClassList(this.pages).then(res => {
					if (res.data.Code === 200) {
						this.tableData = res.data.Data.Data
						this.total = res.data.Data.Num
					}
				})
			},
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						////console.log('提交', this.ruleForm)
						if (this.titles == '新增') {
							var res = await this.SetAddApi()
							if (res.data.Code == 200) {
								this.pages.page = 1
								this.dialogVisible = false
								this.$message.success('操作完成，新增成功')
								this.GetCassListApi()
							}
							////console.log('新增返回', res)
						} else {
							var res = await this.SetEditApi()
							if (res.data.Code == 200) {
								this.pages.page = 1
								this.dialogVisible = false
								this.$message.success('操作完成，编辑成功')
								this.GetCassListApi()
							}
							////console.log('编辑返回', res)
						}
					} else {
						return false;
					}
				});
			},
			clickNew() { //新增
				if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
					this.$refs.ruleForm.resetFields();
				}
				Object.keys(this.ruleForm).forEach(key => (this.ruleForm[key] = ""));
				this.dialogVisible = true

				delete this.ruleForm.T_id
				this.titles = "新增"
			},
			SetEdit(e) { //编辑
				////console.log('编辑', e)
				if (this.$refs.ruleForm) { //为了验证第一次触发表单验证，第二次打开还是触发
					this.$refs.ruleForm.resetFields();
				}
				this.ruleForm.T_name = e.T_name
				this.$set(this.ruleForm, 'T_id', e.Id)
				this.dialogVisible = true
				this.titles = "编辑"
			},
			SetEditApi() {
				return new Promise(resolve => {
					ClassEdit(this.ruleForm).then(res => {
						resolve(res)
					})
				})
			},
			SetAddApi() { //添加Api
				return new Promise(resolve => {
					ClassAdd(this.ruleForm).then(res => {
						resolve(res)
					})
				})
			},
			SetDel(e) { //删除
				////console.log('删除1', e)
				this.$confirm('此操作将执行删除, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(async () => {
					var res = await new Promise(resolve => {
						ClassDel({
							T_id: e.Id
						}).then(res => {
							resolve(res)
						})
					})
					if (res.data.Code === 200) {
						this.$message.success('操作成功，已经删除')
						this.pages.page = 1
						this.GetCassListApi()
					}
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			handleSizeChange(e) { //每页多少条
				this.pages.page_z = e
				this.GetCassListApi()
			},
			handleCurrentChange(e) { //跳转页
				this.pages.page = e
				this.GetCassListApi()
			},
			inputValue(e) {
				this.pages.T_name = e
				this.pages.page = 1
				this.GetCassListApi()
			},
		}
	}
</script>

<style lang="scss">
.y-table{
    height: 100%;
    overflow:auto;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
}
.y-table-item{
    border-right: 1px solid #EBEEF5;
    border-bottom: 1px solid #EBEEF5;
    line-height: 40px;
    padding-left: 10px;
    font-size: 12px;
    color: #606266;
    font-weight: 500;
}
	.el-tabs__content{
		flex: 1;
	}
	.DeviceClass {
		.DeviceClass-T {
			display: flex;
			align-items: center;
			justify-content: space-between;
			background: rgba(#fff, 1);
			height: 70px;
			padding: 15px 10px;
			box-shadow: 5px 5px 10px -10px #000;

			.DeviceClassL {
				display: flex;
				align-items: center;

				.RightsManagement-searchs {
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 20px;

					.DeviceClassit-mai1-btn {}

					.RightsManagement-searchs1 {
						display: flex;
						align-items: center;
						justify-content: center;
					}
				}
			}

			.RightsManagement-searchsMai {
				display: flex;
				align-items: center;
				cursor: pointer;
			}
		}

		.DeviceClass-B {
			background: rgba(#fff, 1);
			margin: 10px;
			padding: 20px 30px;
		}
	}
</style>
